import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { del, post, put } from "@/lib/rest";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { Switch } from "../ui/switch";

const MemberModal = ({
  member,
  orgUsers,
  closeAction,
  isEditMode = false,
  projectId,
}: {
  member: any;
  orgUsers: any;
  closeAction: () => void;
  isEditMode?: boolean;
  projectId: any;
}) => {
  const [selectedMember, setSelectedMember] = useState(member);
  const [addNewUser, setAddNewUser] = useState(false);
  const [open, setOpen] = useState(false);

  const onClickCreate = async () => {
    try {
      await post({
        url: `/api/project/${projectId}/member`,
        data: {
          email: selectedMember.email,
          firstName: selectedMember.first_name,
          lastName: selectedMember.last_name,
          role: selectedMember.role,
          password: selectedMember.password,
        },
      });
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickAdd = async () => {
    try {
      await put({
        url: `/api/project/${projectId}/member`,
        data: { userId: selectedMember.id, role: selectedMember.role },
      });
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickUpdate = async () => {
    try {
      await put({
        url: `/api/project/${projectId}/member/${selectedMember.id}`,
        data: { role: selectedMember.role },
      });
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickRemove = async () => {
    try {
      await del({
        url: `/api/project/${projectId}/member/${selectedMember.id}`,
        data: {},
      });
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <Card className="h-fit max-h-[80%] w-fit min-w-[40rem] overflow-y-auto">
        <CardHeader className="flex w-full justify-start">
          <CardTitle className="w-fit">
            {isEditMode ? "Edit" : addNewUser ? "Create" : "Add"} Member
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            {!isEditMode && (
              <div className="col-span-1 flex flex-col space-y-2">
                <Label className="w-fit">Create User</Label>
                <Switch
                  checked={addNewUser}
                  onCheckedChange={(checked: boolean) => {
                    setAddNewUser(checked);
                    setSelectedMember({
                      email: "",
                      first_name: "",
                      last_name: "",
                      role: "",
                      password: "",
                    });
                  }}
                />
              </div>
            )}
            {!addNewUser && (
              <>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">User</Label>
                  <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        disabled={isEditMode}
                        className="w-[590px] justify-between"
                      >
                        {selectedMember.email
                          ? orgUsers.find(
                              (user: any) =>
                                user.email === selectedMember.email,
                            )?.email
                          : "Select user..."}
                        <ChevronsUpDown className="opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[590px] p-0">
                      <Command>
                        <CommandInput
                          placeholder="Search users..."
                          className="h-9"
                        />
                        <CommandList>
                          <CommandEmpty>No user found.</CommandEmpty>
                          <CommandGroup>
                            {orgUsers.map((user: any) => (
                              <CommandItem
                                key={user.email}
                                value={user.email}
                                onSelect={currentValue => {
                                  setSelectedMember(
                                    currentValue === selectedMember.email
                                      ? ""
                                      : user,
                                  );
                                  setOpen(false);
                                }}
                              >
                                {user.email}
                                <Check
                                  className={`"ml-auto ${selectedMember.id === user.id ? "opacity-100" : "opacity-0"}"`}
                                />
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </div>
                {selectedMember.role !== "admin" ? (
                  <div className="col-span-2 flex flex-col space-y-2">
                    <Label className="w-fit">Role</Label>
                    <Select
                      value={selectedMember.role || ""}
                      onValueChange={(value: any) => {
                        setSelectedMember({ ...selectedMember, role: value });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value={"owner"}>{"owner"}</SelectItem>
                        </SelectGroup>
                        <SelectGroup>
                          <SelectItem value={"developer"}>
                            {"developer"}
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                ) : (
                  <div className="col-span-2 flex flex-col space-y-2">
                    <Label className="w-fit">Role</Label>
                    <Select value={selectedMember.role || ""} disabled={true}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value={"admin"}>{"admin"}</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </>
            )}
            {addNewUser && (
              <>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Email</Label>
                  <Input
                    type="text"
                    id={`email`}
                    placeholder="email"
                    value={selectedMember.email || ""}
                    onChange={e =>
                      setSelectedMember({
                        ...selectedMember,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">First name</Label>
                  <Input
                    type="text"
                    id={`first_name`}
                    placeholder="first name"
                    value={selectedMember.first_name || ""}
                    onChange={e =>
                      setSelectedMember({
                        ...selectedMember,
                        first_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Last name</Label>
                  <Input
                    type="text"
                    id={`last_name`}
                    placeholder="last name"
                    value={selectedMember.last_name || ""}
                    onChange={e =>
                      setSelectedMember({
                        ...selectedMember,
                        last_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Password</Label>
                  <Input
                    type="password"
                    id={`password`}
                    placeholder="password"
                    value={selectedMember.password || ""}
                    onChange={e =>
                      setSelectedMember({
                        ...selectedMember,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Role</Label>
                  <Select
                    value={selectedMember.role || ""}
                    onValueChange={(value: any) => {
                      setSelectedMember({ ...selectedMember, role: value });
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select role" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={"owner"}>{"owner"}</SelectItem>
                      </SelectGroup>
                      <SelectGroup>
                        <SelectItem value={"developer"}>
                          {"developer"}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </>
            )}
          </div>
        </CardContent>
        <CardFooter className="flex justify-between space-x-2 pt-4">
          <div className="flex">
            {selectedMember.role !== "admin" && (
              <Button
                className="w-fit"
                variant="destructive"
                onClick={onClickRemove}
              >
                Remove
              </Button>
            )}
          </div>
          <div className="flex">
            <Button className="w-fit" variant="outline" onClick={closeAction}>
              Cancel
            </Button>
            {selectedMember.role !== "admin" && (
              <Button
                className="ml-4 w-fit"
                onClick={
                  isEditMode
                    ? onClickUpdate
                    : addNewUser
                      ? onClickCreate
                      : onClickAdd
                }
              >
                {isEditMode ? "Save" : addNewUser ? "Create" : "Add"}
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default MemberModal;
