import { Column, Table, TextOption } from "@/interface/config";
import { uuid } from "@/lib/utils";

export function newColumn() {
  const columnId = uuid();
  const newColumn: Column = {
    id: uuid(),
    name: "",
    order: 0,
    type: "text",
    options: {
      min: 0,
      max: 255,
      pattern: "",
      default: "",
      isRichText: false,
      isUnique: false,
      isRequired: false,
    } as TextOption,
  };

  return { columnId, newColumn };
}

export function newTable() {
  const tableId = uuid();
  const newTable: Table = {
    id: tableId,
    name: ``,
    order: 0,
    rules: {
      list: "@request.auth.id !== ''",
      view: "@request.auth.id !== ''",
      create: "@request.auth.id !== ''",
      update: "@request.auth.id !== ''",
      delete: "@request.auth.id !== ''"
    },
    columns: [],
  };

  return { tableId, newTable };
}
