import { useEffect, useState } from "react";
import Logo from "../../assets/logo.svg";
import { Settings } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import DiffView from "@/components/database/DiffView";
import { post } from "@/lib/rest";
import { toast } from "@/components/ui/use-toast";
import { useUserStore } from "@/lib/store";

const NavComponent = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [deploying, setDeploying] = useState(false);
  const currentUser: any = useUserStore((state: any) => state.user);

  const [showModal, setShowModal] = useState<boolean>(false);
  const { oldConfig, prodConfig, fetch } = useDatabaseConfig();

  useEffect(() => {
    navigate(
      `/project/${projectId}/${localStorage.getItem("section") || "data"}`,
    );
  }, []);

  const deploy = async () => {
    setDeploying(true);
    try {
      await post({ url: `/api/database/${projectId}/deploy`, data: {} });
      toast({
        variant: "default",
        title: "Database deployed successfully",
      });
      fetch(projectId);
      setDeploying(false);
      setShowModal(false);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error syncing database",
        description: error.message,
      });
      setDeploying(false);
      setShowModal(false);
    }
  };

  const sectionSetter = (section: string) => {
    localStorage.setItem("section", section);
  };

  return (
    <>
      <nav className="flex h-12 items-center justify-between bg-slate-900">
        <div className="flex h-full items-center justify-start px-5">
          <img
            src={Logo}
            alt="logo"
            style={{ height: 15, width: "auto" }}
            className="h-full w-full cursor-pointer object-cover dark:brightness-[0.2] dark:grayscale"
            onClick={() => {
              navigate("/home");
            }}
          />
        </div>
        <Tabs
          defaultValue={localStorage.getItem("section") || "data"}
          className="w-[400px] bg-transparent"
        >
          <TabsList className="space-x-2 bg-transparent">
            <TabsTrigger
              value="data"
              onClick={() => {
                sectionSetter("data");
                navigate(`/project/${projectId}/data`);
              }}
            >
              Data
            </TabsTrigger>
            <TabsTrigger
              value="ui"
              onClick={() => {
                sectionSetter("ui");
                navigate(`/project/${projectId}/ui`);
              }}
            >
              UI
            </TabsTrigger>
            <TabsTrigger
              value="workflow"
              onClick={() => {
                sectionSetter("workflow");
                navigate(`/project/${projectId}/workflow`);
              }}
            >
              Workflow
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div className="flex items-center px-4 text-white">
          {currentUser.role === "admin" && (
            <Button
              className="mr-4 bg-green-300"
              variant={"secondary"}
              onClick={() => setShowModal(true)}
            >
              Deploy
            </Button>
          )}
          <Settings
            onClick={() => {
              sectionSetter("settings");
              navigate(`/project/${projectId}/settings`);
            }}
          />
        </div>
      </nav>
      {showModal && (
        <DiffView
          syncing={deploying}
          isDeployment={true}
          oldCode={JSON.stringify(prodConfig, null, "\t")}
          newCode={JSON.stringify(oldConfig, null, "\t")}
          closeAction={() => setShowModal(false)}
          confirmAction={deploy}
        />
      )}
    </>
  );
};

export default NavComponent;
