import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Column, Table, TableType } from "@/interface/config";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import { newColumn } from "@/lib/baseData";
import DeleteTableModal from "./DeleteTableModal";
import DeleteColumnModal from "./DeleteColumnModal";
import * as _ from "lodash";
import { ColumnUpdate } from "./ColumnUpdate";
import { Plus } from "lucide-react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import LineEditor from "./line-editor";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";

const CreateTableModal = ({
  table,
  closeAction,
  isEditMode = false,
}: {
  table: Table;
  closeAction: () => void;
  isEditMode?: boolean;
}) => {
  const [showDeleteTableModal, setShowDeleteTableModal] = useState(false);
  const [showDeleteColumnModal, setShowDeleteColumnModal] = useState(false);
  const [localTable, setLocalTable] = useState(table);
  const [selectedColumn, setSelectedColumn] = useState(
    table.columns.length > 0 ? table.columns[0] : null,
  );

  const { config, setConfig, cleanRelations } = useDatabaseConfig();

  function addField() {
    const { newColumn: column } = newColumn();
    setLocalTable({ ...localTable, columns: [...localTable.columns, column] });
    setSelectedColumn(column);
  }

  function deleteTable(tableId: string = localTable.id) {
    setConfig(config.tables.filter(tab => tab.id !== tableId));
    setShowDeleteTableModal(false);
    closeAction();
  }

  function saveTable() {
    let newConfig;
    if (isEditMode) {
      newConfig = {
        ...config,
        tables: [
          ...config.tables.map(tab => {
            if (tab.id === localTable.id) return localTable;
            return tab;
          }),
        ],
      };
    } else {
      newConfig = { ...config, tables: [...config.tables, localTable] };
    }

    cleanRelations(newConfig);
    closeAction();
  }

  function updateColumn(column: Column) {
    setLocalTable({
      ...localTable,
      columns: [
        ...localTable.columns.map(col => (col.id === column.id ? column : col)),
      ],
    });
  }

  function deleteColumn(columnId: string) {
    setLocalTable({
      ...localTable,
      columns: [...localTable.columns.filter(col => col.id !== columnId)],
    });
    setShowDeleteColumnModal(false);
  }

  return (
    <>
      <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
        <Card className="h-fit max-h-[80%] w-fit min-w-[40rem] overflow-y-auto">
          <CardHeader className="flex w-full justify-start">
            <CardTitle className="w-fit">
              {isEditMode ? "Edit" : "Create"} Table
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <div>
              <div className="flex flex-col space-y-2 pb-3">
                <Label htmlFor="order" className="w-fit">
                  Order
                </Label>
                <Input
                  type="number"
                  id="order"
                  placeholder="order"
                  value={localTable?.order || 0}
                  onChange={e =>
                    setLocalTable({
                      ...localTable,
                      order: parseInt(e.target.value),
                    })
                  }
                />
              </div>
              <div className="flex flex-col space-y-2 pb-3">
                <Label htmlFor="name" className="w-fit">
                  Name
                </Label>
                <Input
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={localTable?.name}
                  onChange={e =>
                    setLocalTable({ ...localTable, name: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Label className="w-fit">Type</Label>
                <Select
                  value={localTable.type}
                  onValueChange={(value: TableType) => {
                    setLocalTable({
                      ...localTable,
                      type: value,
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select table type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="Base">Base</SelectItem>
                      <SelectItem value="Auth">Auth</SelectItem>
                      <SelectItem value="Computed">Computed</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger className="w-fit text-sm font-bold leading-none">Rules</AccordionTrigger>
                <AccordionContent>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="listRule" className="w-fit">
                      List
                    </Label>
                    <LineEditor editorValue={localTable?.rules.list} setEditorValue={(value) => setLocalTable({ ...localTable, rules: { ...localTable.rules, list: value } })} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="viewRule" className="w-fit">
                      View
                    </Label>
                    <LineEditor editorValue={localTable?.rules.view} setEditorValue={(value) => setLocalTable({ ...localTable, rules: { ...localTable.rules, view: value } })} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="createRule" className="w-fit">
                      Create
                    </Label>
                    <LineEditor editorValue={localTable?.rules.create} setEditorValue={(value) => setLocalTable({ ...localTable, rules: { ...localTable.rules, create: value } })} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="updateRule" className="w-fit">
                      Update
                    </Label>
                    <LineEditor editorValue={localTable?.rules.update} setEditorValue={(value) => setLocalTable({ ...localTable, rules: { ...localTable.rules, update: value } })} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="deleteRule" className="w-fit">
                      Delete
                    </Label>
                    <LineEditor editorValue={localTable?.rules.delete} setEditorValue={(value) => setLocalTable({ ...localTable, rules: { ...localTable.rules, delete: value } })} />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger className="w-fit text-sm font-bold leading-none">Fields</AccordionTrigger>
                <AccordionContent>
                  {localTable.type !== "Computed" &&
                    localTable?.columns?.length > 0 &&
                    localTable?.columns?.map((column: Column, index: number) => (
                      <div key={index} className="space-y-2">
                        <div
                          className="column-card flex w-full cursor-pointer justify-between rounded-md px-2 py-2 text-[14px] text-slate-500 hover:bg-slate-100"
                          onClick={() => setSelectedColumn(column)}
                        >
                          <div>{column.name}</div>
                          <div className="flex space-x-4">
                            <div>{column.type}</div>
                            <div>
                              {column.options.isRequired
                                ? "Required"
                                : "Not Required"}
                            </div>
                          </div>
                        </div>
                        {selectedColumn && selectedColumn.id === column?.id && (
                          <div>
                            <CardContent className="space-y-2">
                              {selectedColumn && (
                                <ColumnUpdate
                                  selectedTable={localTable}
                                  selectedColumn={selectedColumn}
                                  setSelectedColumn={updateColumn}
                                />
                              )}
                            </CardContent>
                          </div>
                        )}
                      </div>
                    ))}
                  <div className="my-4" />
                  {localTable.type !== "Computed" && (
                    <Button
                      type="button"
                      variant="secondary"
                      className="w-full"
                      onClick={() => {
                        addField();
                      }}
                    >
                      <Plus className="mr-4" /> Add Field
                    </Button>
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </CardContent>
          <div className="my-4" />
          <CardFooter className="flex justify-end space-x-2">
            <Button
              className="w-fit"
              variant="destructive"
              onClick={() => {
                deleteTable();
              }}
            >
              Delete
            </Button>
            <Button
              className="w-fit"
              variant="outline"
              onClick={() => {
                closeAction();
              }}
            >
              Cancel
            </Button>
            <Button className="w-fit" onClick={saveTable}>
              {isEditMode ? "Save" : "Create"}
            </Button>
          </CardFooter>
        </Card>
      </div>

      {showDeleteColumnModal && (
        <DeleteColumnModal
          table={localTable}
          column={selectedColumn}
          closeAction={() => setShowDeleteColumnModal(false)}
          confirmAction={deleteColumn}
        />
      )}

      {showDeleteTableModal && (
        <DeleteTableModal
          table={localTable}
          closeAction={() => setShowDeleteTableModal(false)}
          deleteTable={() => deleteTable(localTable.id)}
        />
      )}
    </>
  );
};

export default CreateTableModal;
