import { DiffEditor } from "@monaco-editor/react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Button } from "../ui/button";
import { Loader2 } from "lucide-react";

const DiffView = ({
  syncing,
  isDeployment,
  newCode,
  oldCode,
  closeAction,
  confirmAction,
}: {
  syncing: boolean;
  isDeployment: boolean;
  newCode: string;
  oldCode: string;
  closeAction: () => void;
  confirmAction: () => void;
}) => {
  return (
    <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <Card className="h-fit max-h-[80%] w-[80%] min-w-[40rem] overflow-y-auto">
        <CardHeader className="flex w-full justify-start">
          <CardTitle className="w-fit">Schema changes</CardTitle>
        </CardHeader>
        <CardContent>
          <DiffEditor
            height="500px"
            width="100%"
            original={oldCode}
            modified={newCode}
            language="json"
            theme="light"
            options={{
              originalEditable: false, // Allow editing of the original code
              readOnly: true, // Allow editing of modified code
              renderSideBySide: true, // Side-by-side view
            }}
          />
        </CardContent>
        <CardFooter className="flex justify-end space-x-2 pt-4">
          <Button className="w-fit" variant="outline" onClick={closeAction}>
            Cancel
          </Button>
          {!syncing ? (
            <Button
              variant={"secondary"}
              className="mr-4 bg-green-300"
              onClick={() => {
                confirmAction();
              }}
            >
              {isDeployment ? "Deploy" : "Sync"}
            </Button>
          ) : (
            <Button disabled className="mr-4 bg-green-300 text-slate-900">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {isDeployment ? "Deploying" : "Syncing"}
            </Button>
          )}
        </CardFooter>
      </Card>
    </div>
  );
};

export default DiffView;
