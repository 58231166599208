import { useState } from "react";
import {
  DatabaseConfig,
  Column,
  Table,
  RelationOption,
} from "@/interface/config";
import { get } from "@/lib/rest";
import { useConfigStore } from "@/lib/store";
import _ from "lodash";
import { getTable, uuid } from "@/lib/utils";

export function useDatabaseConfig() {
  const config: DatabaseConfig = useConfigStore((state: any) => state.config);
  const setConfig = useConfigStore((state: any) => state.setConfig);
  const oldConfig: DatabaseConfig = useConfigStore(
    (state: any) => state.oldConfig,
  );
  const setOldConfig = useConfigStore((state: any) => state.setOldConfig);
  const prodConfig: DatabaseConfig = useConfigStore(
    (state: any) => state.prodConfig,
  );
  const setProdConfig = useConfigStore((state: any) => state.setProdConfig);
  const project: any = useConfigStore((state: any) => state.project);
  const setProject = useConfigStore((state: any) => state.setProject);

  const [loading, setLoading] = useState(false);

  function onChangeType(column: Column): any {
    switch (column.type) {
      case "text":
        return {
          min: 0,
          max: 255,
          pattern: "",
          default: "",
          isRichText: false,
          isUnique: false,
          isHashed: false,
          isRequired: false,
          isHidden: false,
        };
      case "email":
      case "url":
      case "phoneNumber":
        return { pattern: "", default: "", isUnique: false, isHidden: false };
      case "number":
        return {
          min: null,
          max: null,
          default: 0,
          hasDecimal: false,
          isHidden: false,
        };
      case "date":
        return { min: "", max: "", default: "", isHidden: false };
      case "checkbox":
        return { default: false, isHidden: false };
      case "select":
        return {
          selectOptions: [],
          hasMultiple: false,
          default: "",
          isHidden: false,
        };
      case "relation":
        return {
          isParent: true,
          tableId: "",
          hasMultiple: false,
          columnId: "",
          backColumnName: "",
          hasMultipleBack: false,
          isHidden: false,
          isRequired: false,
        };
      case "image":
        return {
          maxFileSize: 0,
          fileTypes: [],
          hasMultiple: false,
          isProtected: false,
          isHidden: false,
        };
      case "file":
        return {
          maxFileSize: 0,
          fileTypes: [],
          hasMultiple: false,
          isProtected: false,
          isHidden: false,
        };
      case "json":
        return { nesting: 1, default: {}, isHidden: false };
    }
  }

  function handleColumnChange(
    table: Table,
    column: Column,
    key: string,
    value: any,
  ) {
    const updatedColumn: Column = { ...column };

    switch (key) {
      case "order":
        updatedColumn.order = parseInt(value);
        break;
      case "name":
        updatedColumn.name = value;
        break;
      // [WIP] if changed from relation remove also the back relation
      case "type":
        updatedColumn.type = value;
        updatedColumn.options = onChangeType(updatedColumn);
        break;

      default:
        (updatedColumn.options as any)[key] = value;
        break;
    }

    const columns = table.columns.map(col => {
      if (col.id === updatedColumn.id) return updatedColumn;
      return col;
    });

    return {
      table: { ...table, columns },
    };
  }

  function cleanRelations(newConfig: DatabaseConfig) {
    const visitedRelations: string[] = [];
    newConfig.tables.forEach(table => {
      table.columns.forEach(column => {
        if (
          column.type === "relation" &&
          (column.options as RelationOption).isParent
        ) {
          if ((column.options as RelationOption).columnId === "") {
            const childColumnUuid = uuid();
            visitedRelations.push(column.id, childColumnUuid);
            if (
              _.has(column.options, "backColumnName") &&
              _.has(column.options, "hasMultipleBack")
            ) {
              (column.options as RelationOption).columnId = childColumnUuid;
              const childTable = getTable(
                newConfig,
                (column.options as RelationOption).tableId,
              );
              childTable.columns.push({
                id: childColumnUuid,
                order: 0,
                name: (column.options as any).backColumnName,
                type: "relation",
                options: {
                  isRequired: false,
                  isHidden: false,
                  isParent: false,
                  tableId: table.id,
                  columnId: column.id,
                  hasMultiple: (column.options as any).hasMultipleBack,
                } as any,
              });

              delete (column.options as any).backColumnName;
              delete (column.options as any).hasMultipleBack;
            }
          } else {
            const childTable = getTable(
              newConfig,
              (column.options as RelationOption).tableId,
            );
            if (childTable) {
              const childColumn = childTable.columns.filter(
                col => col.id === (column.options as RelationOption).columnId,
              )[0];
              visitedRelations.push(column.id, childColumn.id);
              if (
                _.has(column.options, "backColumnName") &&
                _.has(column.options, "hasMultipleBack")
              ) {
                childColumn.name = (column.options as any).backColumnName;
                (childColumn.options as RelationOption).hasMultiple = (
                  column.options as any
                ).hasMultipleBack;

                delete (column.options as any).backColumnName;
                delete (column.options as any).hasMultipleBack;
              }
            }
          }
        }
      });
    });

    newConfig.tables.forEach(table => {
      const deleteColumns: string[] = [];
      table.columns.forEach(column => {
        if (column.type === "relation") {
          if (!visitedRelations.includes(column.id)) {
            deleteColumns.push(column.id);
          }
        }
      });
      table.columns = table.columns.filter(
        column => !deleteColumns.includes(column.id),
      );
    });

    setConfig(newConfig);
  }

  function fetch(projectId: any) {
    setLoading(true);
    get({ url: `/api/project/${projectId}` })
      .then(response => {
        if (response.data.projectData) {
          setProject(response.data.projectData);
          const data =
            response.data.projectData.configuration.new_configuration;
          const oldData =
            response.data.projectData.configuration.old_configuration;
          const prodConfig =
            response.data.projectData.prodConfiguration.old_configuration;
          setConfig(data);
          setOldConfig(oldData);
          setProdConfig(prodConfig);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  return {
    config,
    setConfig,
    oldConfig,
    prodConfig,
    project,
    handleColumnChange,
    fetch,
    loading,
    cleanRelations,
  };
}
