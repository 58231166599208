import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const apiServer = process.env.REACT_APP_API_SERVER;

export const sOptions = {
  server: process.env.REACT_APP_API_SERVER || "",
  neonProjectId: process.env.PROJECT_ID || "",
};

let config = {
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

let fileConfig = {
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const get = ({ url }: { url: string }) => {
  return axios.request({
    method: "get",
    ...config,
    url: `${apiServer}${url}`,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

export const post = ({ url, data }: { url: string; data: any }) => {
  return axios.request({
    method: "post",
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
    url: `${apiServer}${url}`,
    data,
  });
};

export const filePost = ({ url, data }: { url: string; data: any }) => {
  return axios.request({
    method: "post",
    ...fileConfig,
    headers: {
      ...fileConfig.headers,
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
    url: `${apiServer}${url}`,
    data,
  });
};

export const put = ({ url, data }: { url: string; data: any }) => {
  return axios.request({
    method: "put",
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
    url: `${apiServer}${url}`,
    data,
  });
};

export const del = ({ url, data }: { url: string; data: any }) => {
  return axios.request({
    method: "delete",
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
    url: `${apiServer}${url}`,
    data,
  });
};
