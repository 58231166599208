import { Monaco, loader } from '@monaco-editor/react';
import Editor from '@monaco-editor/react';
import { CancellationToken, editor, Position, languages } from 'monaco-editor';

const LineEditor = ({ editorValue, setEditorValue }: { editorValue: string, setEditorValue: (value: string) => void }) => {
    const handleEditorDidMount = (editor: any, monaco: Monaco) => {
        monaco.editor.defineTheme('stark', {
            base: 'vs',
            inherit: true,
            rules: [
                {
                    token: 'keyword', foreground: '#FF6600', fontStyle: 'bold'
                },
                {
                    token: 'comment', foreground: '#999999'
                },
                {
                    token: 'string', foreground: '#009966'
                },
                {
                    token: 'variable', foreground: '#006699'
                },
                {
                    token: 'number', foreground: '#FF0000'
                },
                {
                    token: 'delimiter', foreground: '#FF6600'
                },
                {
                    token: 'operator', foreground: '#FF6600'
                },
                {
                    token: 'identifier', foreground: '#000000'
                },
                {
                    token: 'type', foreground: '#0000FF'
                }
            ],
            colors: {
                'editor.background': '#f1f1f1',
            },
        });
        monaco.editor.setTheme('stark');
        monaco.languages.register({ id: 'stark' });
        monaco.languages.setMonarchTokensProvider('stark', {
            keywords: ['@request', '@table'],
            typeKeywords: [
                'boolean', 'double', 'byte', 'int', 'short', 'char', 'void', 'long', 'float'
            ],
            // we include these common regular expressions
            symbols: /[=><!~?:&|+\-*\/\^%]+/,

            operators: ['>', '<', '=', '~', '!=', '>=', '<=', '&&', '||', '+', '-', '*', '^'],
            tokenizer: {
                root: [
                    // identifiers and keywords
                    [/@?[a-zA-Z][\w$]*/, {
                        cases: {
                            '@typeKeywords': 'keyword',
                            '@keywords': 'keyword',
                            '@default': 'identifier'
                        }
                    }],

                    // delimiters and operators
                    [/[{}()\[\]]/, '@brackets'],
                    [/[<>](?!@symbols)/, '@brackets'],
                    [/@symbols/, {
                        cases: {
                            '@operators': 'operator',
                            '@default': ''
                        }
                    }],

                    // numbers
                    [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
                    [/0[xX][0-9a-fA-F]+/, 'number.hex'],
                    [/\d+/, 'number'],

                    // delimiter: after number because of .\d floats
                    [/[;,.]/, 'delimiter'],

                    // strings
                    [/'([^']*)'/g, 'string'],

                    // characters
                    [/'[^\\']'/, 'string'],
                    [/'/, 'string.invalid']
                ]
            },
        });
        monaco.languages.registerCompletionItemProvider('stark', {
            provideCompletionItems: (model: editor.ITextModel, position: Position, context: languages.CompletionContext, token: CancellationToken): languages.ProviderResult<languages.CompletionList> => {
                console.log(position, model, context, token);
                const suggestions = [
                    {
                        label: '@request',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: '@request',
                        detail: 'Request object from the api call',
                        range: new monaco.Range(position.lineNumber, position.column, 1, 1)
                    },
                    {
                        label: '@table',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: '@table',
                        detail: 'Reference other tables',
                        range: new monaco.Range(position.lineNumber, position.column, 1, 1)
                    },
                ];
                return { suggestions: suggestions };
            },
        });
    };

    const handleEditorValidation = (markers: any[]) => {
        console.log(markers);
    }

    return (
        <Editor
            height="50px"
            defaultLanguage="stark"
            defaultValue=''
            options={{
                lineNumbers: 'off',
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                wordWrap: 'on',
                automaticLayout: true,
                scrollbar: {
                    vertical: 'hidden',
                    horizontal: 'hidden'
                },
                overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true,
                renderLineHighlight: 'none',
                folding: false,
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                glyphMargin: false,
                padding: {
                    top: 0,
                    bottom: 0
                }
            }}
            value={editorValue}
            onChange={(value) => setEditorValue(value || "")}
            onMount={handleEditorDidMount}
            onValidate={handleEditorValidation}
        />
    );
};

export default LineEditor;